
const soleProprietor = {
    id: 0,
    jobTitle: "Sole Proprietor - Full Stack Software Developer",
    date: "01/2023 - Present",
    company: "Sole Proprietor in Canada",
    description: `
        As a part-time/occasional sole proprietor as a full stack software developer,
        I offer high-quality software development services to a diverse range of clients,
        including small startups, large corporations,
        and businesses of all types looking to establish an online presence or build custom applications.    
    `,
}
const straffr = {
    id: 1,
    jobTitle: "Software Developer",
    date: "12/2021 - 12/2022",
    company: "At STRAFFR GMBH in Germany",
    description: `
        Straffr is a German fitness company that produces smart bands which can be connected to mobile phones via Bluetooth.
        Since it is a start-up, I collaborated with Straffr team to complete various tasks
        such as implementing and deploying back-end cloud functions, NoSQL database CRUD operations, data analysis, machine learning, etc.
    `,
}

const freelancing = {
    id: 2,
    jobTitle: "Freelancing as a Software Developer",
    date: "02/2021 - 05/2022",
    company: "On Fiver",
    description: `
        I started to take on freelance projects while studying Computer Science.
        At that time, freelancing offered me more control and flexibility than a traditional job.
        Additionally, freelance work gave me the opportunity to work on a variety of projects and topics, thus
        enhancing my skills as a full stack developer.
    `,
}

const tutor = {
    id: 3,
    jobTitle: "Coding Tutor",
    date: "05/2020 - Present",
    company: "Sole Proprietor",
    description: `
        In my free time I love to teach programming.
        I tutor all ages starting from the age of 5.
        One of my goals is to improve people's lives, and I believe that
        educating adults and children on programming would make a very positive difference.
        This would also empower them to achieve their full potential.
    `,
}

const computerScienceStudent = {
    id: 4,
    jobTitle: "Computer Science Student",
    date: "10/2019 - 11/2022",
    company: "At Leibniz University Hannover in Germany",
    description: `
        At the Leibniz University of Hannover, I learned all the academic skills and knowledge that cannot be acquired through work.
        These include Math (I, II, and III), Statistics, Analysis, Algebra, etc.
        In addition, I have also learned about Operating Systems,
        Software Engineering, Data Structures and Algorithms.
        All this has added significant value to my work thereafter.
    `,
}

const foundationCourseStudent = {
    id: 5,
    jobTitle: "Technical Foundation Course Student",
    date: "10/2018- 06/2019",
    company: "At Leibniz University Hannover in Germany",
    description: `
        This is a one-year course completed after graduating from high school.
        During this course I learned all the required skills to prepare myself for the field of Computer Science.
    `,
}

const timelineArr = [soleProprietor, straffr, freelancing, tutor, computerScienceStudent, foundationCourseStudent]

export default timelineArr
